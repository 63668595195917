@font-face {
  font-family: 'DMSans-Regular';
  src: url('./assets/fonts/DM-Sans/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: url('./assets/fonts/DM-Sans/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url('./assets/fonts/DM-Sans/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./assets/fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./assets/fonts/Inter/Inter-Bold.ttf');
}

.nasihaApp {
  font-family: 'Inter-Regular', 'DMSans-Regular', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .MuiOutlinedInput-root {
    border-radius: 20px !important;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card-custom {
    background: #ffffff !important;
    box-shadow: 2px 4px 16px rgb(0 0 0 / 10%) !important;
    border-radius: 8px !important;
}

.card-custom-outlined {
     box-shadow: none !important;
     border-radius: 8px !important;
     background: #FEF1F8 !important;
     /* opacity: 0.5; */
}

.card-outlined {
    box-shadow: none !important;
    border-radius: 8px !important;
    /* border: 2px solid #FEF1F8 !important */
    /* opacity: 0.5; */
}

.MuiButton-contained {
    box-shadow: none  !important;
    border-radius: 8px;
    color: white;
}


.price-bundling {
    font-size: 20px;
    color: #D64D6F;
    font-family: "DMSans-Regular";
    font-weight: bold;
}



.price-strike-bundling {
    color: #959595;
    font-size: 13px;
    margin-top:8px;
    font-family: "DMSans-Regular";
    text-decoration: line-through;
}

 .percentage-bundling {
     margin-left: 8px;
     color: #4db6ac;
     font-size: 12px;
     font-family: "DMSans-Regular";
     background: rgba(116, 214, 214, 0.2);
     border-radius: 4px;
     padding: 0px 8px;
     text-align: center;
 }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
